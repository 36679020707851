.MuiSvgIcon-root {
  fill: #1d7be3;
}
.MuiPaper-elevation4 {
  box-shadow: none !important;
  border-bottom: 3px solid #f1f9fe;
}
.MuiDivider-root {
  background-color: #aeb9c0 !important;
  height: 5px;
}
.MuiMobileStepper-root {
  background: none !important;
}
