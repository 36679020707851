.succModalDiv {
  width: 328px;
}
.succModalTitleDiv {
  background: linear-gradient(90deg, #409A95 -17.74%, #34CEC6 100%);
}
.succModalTitle {
  font-size: 15px;
  letter-spacing: 0.01em;
  color: #ffffff;
  font-family: "Roboto-Medium";
}
.succModalIcon {
  stroke: #b8bfc5;
  float: right;
  cursor: pointer;
}
.succModalContent {
  border: 1px solid #cde0f1;
}
.succModalContentText {
  margin: 0;
  padding: 9px;
  font-weight: normal;
  font-size: 14px;
  color: #494949;
  font-family: "Roboto-Regular";
}
