.requestHoldTitle {
  width: 183px;
}
.requestHoldTitleIcon {
  stroke: #b8bfc5;
  float: right;
}
.requestHoldButton {
  background: #2ca4c0 !important;
  color: white !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 15px !important;
  letter-spacing: 0.01em !important;
}
.requestHoldMailDialog {
  width: 328px;
  height: auto;
}
.requestHoldCancelButton {
  width: 120px;
  color: black !important;
  font-style: normal;
  font-weight: 500;
  font-size: 15px !important;
  letter-spacing: 0.01em;
  border: 1px solid #2ca4c0 !important;
}
.requestPhotoButton {
  background: linear-gradient(
    90deg,
    rgb(64, 154, 149) -17.74%,
    rgb(52, 206, 198) 100%
  );
  color: white !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 15px !important;
  letter-spacing: 0.01em !important;
}
.pickupHoldModalTitle {
  height: 21px;
  font-size: 15px;
  color: white;
  font-family: "Roboto-Medium";
  letter-spacing: 0.01em;
  color: #242223;
}
