.forwarding-address-dialog-title {
  width: 545px !important;
  height: auto;
  border: none;
}
.faddressModalHeader {
  background: #f1f9fe;
  border: 1px solid #cde0f1;
  box-sizing: border-box;
}
.faddressModalHeaderText {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  /* line-height: 140%; */
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #242223;
  font-family: "Roboto-Medium";
}
.faddressModalHeaderIconGrid {
  display: flex;
  justify-content: flex-end;
}
.faddressContainer {
  padding-left: 2px;
  padding-top: 25px;
}
.OfficeNameFieldLabel {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  /* line-height: 16px; */
  /* display: flex; */
  /* align-items: center; */
  letter-spacing: 0.01em;
  color: #242223;
  font-family: "Roboto-Bold";
}
.OfficeNameFieldInputGrid {
  margin-top: 8px;
}
.OfficeNameFieldInput {
  border-radius: unset;
}
.OfficeNameFieldInputDivider {
  border-color:#7d7d7d;
  width: 100%;
}
.fAddressLine1FieldInputGrid {
  margin-top: 25px;
}
.faddressSubmitButton {
  width: 90%;
  background: #2ca4c0 !important;
  color: white !important;
}
.faddressCancelButton {
  width: 100%;
  background: white !important;
  border: 1px solid #2ca4c0 !important;
  color: black !important;
  font-family: "Roboto-Medium";
}
