.reqForwardModalDialog {
  width: 648px;
  height: 648px;
}
.selectedRoomModal {
  width: 520px;
  height: auto;
}
.reqForwardModalHeader {
  background: #f1f9fe;
  height: 42.69px;
}
.reqForwardModalTitle {
  width: 133px;
  height: 21px;
  font-size: 15px;
  color: #242223;
  font-family: "Roboto-Medium";
  letter-spacing: 0.01em;
}
.reqForwardModalCloseIcon {
  float: right;
  stroke: #b8bfc5;
  cursor: pointer;
}
.reqForwardModalLabel {
  height: 16px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  width: 301px;
  letter-spacing: 0.01em;
  color: #242223 !important;
  margin-bottom: 5px;
}
.reqForwardModalAddressField {
  margin-top: 20px;
}
.optionsLabel {
  color: #242223;
  font-size: 14px;
}
.divider {
  margin-top: 36px;
  width: 100% !important;
  background: #f0f0f0 !important;
}
.deliveryOptionsDropdown {
  width: 301px;
  height: 19px;
  font-size: 16px;
  color: #242223;
  font-weight: bold;
}
.desliveryOptionsTable {
  margin-top: 20px;
}
.deliveryOptionsHeading {
  justify-content: space-between;
  width: 600px;
  height: 35.57px;
  background: #f1f9fe;
  border: 1px solid #cde0f1;
}
.deliveryOptionsHeadingRow {
  padding: 8px 53px !important;
}
.deliveryOptionsHeadingRowData {
  padding-left: 38px !important;
}
.deliveryOptionsTableBody {
  background: #f7f6f7;
  font-size: 15px;
  line-height: 17.58px;
  color: #494949;
  font-style: normal;
  font-weight: normal;
  height: 100px !important;
}
.deliveryOptionsCheckbox {
  position: relative;
  right: 30px;
}
.deliveryOptionsTotal {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  background: #ffffff;
  border: 2px solid #8eeaff;
  box-sizing: border-box;
  height: 40px;
}
.deliveryOptionsTotalValue {
  margin-left: 58%;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  color: #242223;
}
.reqForwardModalCautionCol {
  display: flex;
}
.reqForwardModalCautionText {
  height: 28px;
  font-size: 12px;
  width: 187px;
  margin-left: 8px;
  line-height: 16.06px;
  font-style: italic;
}
.reqForwardModalButton {
  border-radius: 2px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #ffffff !important;
  width: 160px !important;
  background: linear-gradient(90deg, #409a95 -17.74%, #34cec6 100%) !important;
  font-size: 13px !important;
  margin-right: 5px !important;
}
.reqForwardModalButton:hover {
  background: linear-gradient(90deg, #409a95 -17.74%, #34cec6 100%) !important;
}
.reqForwardModalCancelButton {
  border: 2px solid #cde0f1 !important;
  font-size: 13px !important;
  width: 136px !important;
}
.reqForwardModalContainer {
  margin-top: 20px;
}
.recipientCarot {
  position: relative;
  bottom: 22px;
  float: right;
  right: 20px;
}
.forwardingAddressGrid {
  margin-bottom: 20px;
}
.forwardingAddressGridTable {
  margin-top: 15px;
}
.deliveryOptionsTotalGrid {
  margin-top: 7px;
}
