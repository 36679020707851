
@keyframes transformEmail {
    0% {
        transform: translate(0%, -30%);
        opacity: 0.5;
    }

    100% {
        transform: none;
        opacity: 1;
    }
}

@keyframes transformPassword {
    0% {
        transform: translate(0%, -30%);
        opacity: 0.5;
    }

    100% {
        transform: none;
        opacity: 1;
    }
}

@keyframes transformButton {
    0% {
        transform: translate(50%, 50%);
        opacity: 0.5;
    }

    100% {
        transform: none;
        opacity: 1;
    }
}

@keyframes transformRightButton {
    0% {
        transform: translate(-50%, -50%);
    }

    100% {
        transform: none;
    }
}