@import "./fonts.scss";
@import "./reset.scss";
@import "./animations.scss";

.MuiDataGrid-columnHeaderTitleContainer {
  padding: 0 !important;
}

.MuiDataGrid-sortIcon {
  opacity: 1 !important;
}

.MuiDataGrid-dataContainer {
  min-width: 100%;
}

.MuiDialogContent-root {
  padding: 24px !important;
}

.MuiDialogActions-root {
  padding: 0 24px 24px !important;
}

.MuiDialogTitle-root {
  height: auto !important;
  padding: 12px 24px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}

.MuiOutlinedInput-root.Mui-focused {
  border: 2px solid rgb(142, 234, 255) !important;
}

.rc-time-picker-input {
  height: 100%;
  font-size: 14px;
  border-radius: 0;
}

.rc-time-picker {
  &-clear {
    top: 10px;
  }

  &-icon {
    font-size: 18px;
  }
}
.pn-msg-list {
  flex: auto;
}
.pn-msg-list-scroller,.pn-member-list {
  padding: 10px;
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: grey;
  }
}

.pn-msg-input{
  padding-bottom: 20px;
}
