.shredMailDialog {
  width: 328px;
  height: auto;
}
.shredMailCancelButton {
  width: 120px;
  border: 1px solid #d95767 !important;
  font-size: 15px !important;
  color: #494949 !important;
  font-family: "Roboto-Medium" !important;
}
