.ScanPreviewModalTitleDiv {
  background: #f1f9fe;
}
.ScanPreviewModalTitleContentDiv {
  display: flex;
  padding: 10px 20px;
  justify-content: space-between;
}
.ScanPreviewModalTitle {
  /* font-family: Roboto; */
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  /* line-height: 140%; */
  display: flex;
  align-items: center;
  /* letter-spacing: 0.01em; */
  color: #242223;
  font-family: "Roboto-Medium";
}
.ScanPreviewModalTitleIcon {
  stroke: #b8bfc5 !important;
}
.ScanPreviewModalContentDiv {
  display: flex;
  align-items: center;
  /* padding: 10px 0px; */
  padding-top: 10px;
}
.ScanPreviewModalContent {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  /* line-height: 16px; */
  color: #242223;
  font-family: "Roboto-Bold";
}
.ScanPreviewModalDocumentName {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #242223;
  font-family: "Roboto-Regular";
  /* padding-left: 20px; */
  margin: 0;
}
.ScanPreviewModalMainContentDiv {
  padding: 10px 20px;
}
.react-pdf__Page__canvas {
  margin: 0;
  width: 80% !important;
  height: 100% !important;
}
