.junk-dialog-title {
  width: 328px !important;
  border: 1px solid #d95767;
}
.junkModalHeader {
  background: #d95767;
  height: 48px;
}
.junkModalTitle {
  width: 100px;
  height: 21px;
  font-size: 15px;
  color: white;
  font-family: "Roboto-Medium";
  letter-spacing: 0.01em;
}
.junkModalText {
  font-size: 14px;
  line-height: 150%;
}
.junkModalBodyTextIcon {
  margin-top: 20px;
  margin-left: 12px;
}
.junkModalCautionText {
  position: relative;
  margin-left: 30px;
  bottom: 16px;
  color: #d95767;
  font-size: 12px;
  width: 235px;
  font-style: italic;
}
.junkModalCautionTextFree {
  position: relative;
  margin-top: 30px;
  bottom: 16px;
  font-size: 12px;
  width: 235px;
  font-style: italic;
}
.junkModalFooter {
  border-top: none !important;
  justify-content: center !important;
}
.junkModalFooterButton {
  width: 132px;
  height: 40px;
  border-radius: 2px;
  background: #d95767 !important;
  color: white !important;
}
.junkModalFooterButton:hover {
  background: #d95767 !important;
}
.junkModalFooterButton:focus {
  outline: none !important;
}
.junkModalFooterCancelButton {
  background: white;
  color: #494949;
  border: 1px solid #d95767;
  border-radius: 2px;
  width: 132px;
  font-size: 15px !important;
  height: 40px;
  border: 1px solid #d95767 !important;
  font-weight: 500;
  font-family: "Roboto-Medium" !important;
}
.junkModalFooterCancelButton:hover {
  background: white !important;
  color: black !important;
}
.junkModalFooterCancelButton:focus {
  outline: none !important;
}
.junkDialogCloseIcon {
  float: right;
}
