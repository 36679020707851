.mailNav {
  background-color: #102f51;
}
.navLink {
  color: white !important;
  font-family: "Roboto-Medium";
  font-size: 15px;
}
.pendingTab {
  padding: 20px;
}
.navLink:hover {
  color: #8eeaff !important;
}
.navGroup {
  margin-left: 70px;
}
.navSearchField {
  display: flex;
  margin-left: 405px;
}
.search-icon {
  margin-top: 14px;
  position: absolute;
  display: flex;
  right: 70px;
}
.searchBox {
  border-radius: 0 !important;
  width: 360px;
}
.mailButtonGroup {
  /* margin-top: 20px !important; */
  padding: 5px 30px 13px 30px !important;
}
.formButton {
  height: 45px;
  /* height: 218% !important; */
  font-size: 13px !important;
  /* width: 181px !important; */
  background-color: white !important;
  color: #a19e9e !important;
  border: 1px solid #dfdfe4 !important;
  font-family: "Roboto-Medium" !important;
  width: 100%;
  padding: 15px 0px;
  display: flex;
  justify-content: space-around;
}
.iconSvg {
  display: inline-block;
  /* position: fixed; */
}
.active path {
  stroke: #2ca4c0;
  fill: #2ca4c0;
}

.activeDelete path {
  stroke: #2ca4c0;
}
.activeTab {
  color: #8eeaff !important;
}
.tableGrid {
  /* margin-top: 60px; */
}
.activeFormButton {
  color: #242223 !important;
  font-style: normal;
  font-family: Roboto-Medium !important;
  font-weight: 500;
  border: 1px solid #cde0f1 !important;
}
.mailActionsGrid {
  margin: 0 !important;
}
.mailActionsButtonGrid {
  padding: 15px 10px 15px 0 !important;
}
.mailActionsLastButtonGrid {
  padding-right: 0 !important;
}
.loading {
  display: flex;
  justify-content: center;
}
.loading circle {
  stroke: #102f51 !important;
}
.pagination-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.table-wrap{
  flex: auto;
}
.table-wrap > div:first-child{
  height: auto !important;
}

.table-wrap .MuiDataGrid-root .MuiDataGrid-window{
  overflow-x: hidden;
}