.rs-picker-toggle-wrapper {
  display: none !important;
}
.rs-picker-menu {
  left: 429px !important;
  top: 131px !important;
}
.rs-picker-daterange-header {
  display: none !important;
}
.rs-picker-toolbar-ranges {
  display: none;
}
.rs-calendar-table-cell-content {
  text-decoration: none !important;
}
.rs-picker-toolbar {
  border-top: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rs-picker-toolbar-right-btn-ok {
  width: 91px;
  background: #494949 !important;
}
.rs-picker-toolbar-right-btn-ok:hover {
  width: 91px;
  background: #494949 !important;
}
.customDiv {
  width: 91px;
  height: 34px;
  background: #ffffff;
  border: 2px solid #f2f6fa;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}
.customButton {
  background: #ffffff;
  width: 91px;
  padding-top: 7px;
  color: #494949;
  font-family: "Roboto-Medium";
}
.tui-chart-chartExportMenu-area {
  display: none;
}
.tui-chart text {
  display: none;
}
.tui-chart rect {
  display: none;
}
.custom .MuiLinearProgress-dashed {
  display: none;
}
.custom .MuiLinearProgress-bar2Buffer {
  display: none;
}
/* .custom .MuiLinearProgress-colorSecondary {
  display: none;
} */
.customGreen .MuiLinearProgress-bar1Buffer {
  background-color: #07ad8f !important;
}
.forwardingMailProgress {
  width: 100%;
}
.spaceUsageProgress {
  width: 100%;
}
.forwardingMailProgress .MuiLinearProgress-bar1Buffer {
  background-color: #102f51 !important;
}
.spaceUsageProgress .MuiLinearProgress-bar1Buffer {
  background-color: #d95767 !important;
}
