.requestScanHeader {
  background: #f1f9fe !important;
  border: 1px solid #cde0f1 !important;
}
.requestScanTitle {
  color: #242223 !important;
}
.requestScanTitleIcon {
  color: #242223 !important;
}
.scanOptionsHeading {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: #242223;
}
.list-group-item {
  border: none !important;
}
.scanOptionsCheckbox {
  position: relative;
  right: 10px;
}
.scanOptionsText {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
}
.scanDialog {
  height: auto;
  width: 328px;
}
.scanOptionsText span {
  font-size: 14px !important;
}
.customRangeInput {
  width: 72px;
  background: #f1f9fe;
  border: 1px solid #cde0f1;
  height: 32px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  padding-left: 15px;
  font-family: "Roboto-Medium";
}
.customRangeInputList {
  padding-left: 9px !important;
}
.reqScanModalButton {
  width: 95% !important;
  background: linear-gradient(90deg, #409a95 -17.74%, #34cec6 100%) !important;
  border-radius: 2px !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 15px !important;
  letter-spacing: 0.01em !important;
  color: #ffffff !important;
  font-family: "Roboto-Regular" !important;
}
.reqScanCancelModalButton {
  border: 2px solid #8eeaff !important;
  width: 95% !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 15px !important;
  letter-spacing: 0.01em !important;
  color: #494949 !important;
  font-family: "Roboto-Medium" !important;
}
